<body class="deleteUserModel">
  <button class="close-btn closeDeleteUserModel" (click)="openCloseDeleteUserModal()">Close</button>
  <h1 class="modal-title text-center">Confirm Delete</h1>

  <div class="userInfo">
    <h4>Name: {{ userModel.displayName }}</h4>
    <h4>Email: {{ userModel.email }}</h4>
    <h4>Phone: {{ userModel.phoneNumber }}</h4>
  </div>

  <button class="btn btn-yellow w-132 deleteUser" (click)="deleteUser(this.userModel)">
    Delete User
  </button>
  <button class="btn btn-yellow w-132 cancelDeleteUser" (click)="openCloseDeleteUserModal()">
    Cancel
  </button>
</body>
