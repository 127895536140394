import {LegalContentItem} from './db-models/media';

export const defaultLegalContentItems: LegalContentItem[] = [
  {
    title: 'Cookie policy',
    htmlContent: `   <h1>Cookie policy</h1>
      <p>
        This cookie policy (&#8220;Policy&#8221;) describes what cookies are and how and
        they&#8217;re being used by the <a href="http://terrific.live">terrific.live</a> website
        (&#8220;Website&#8221; or &#8220;Service&#8221;) and any of its related products and
        services (collectively, &#8220;Services&#8221;). This Policy is a legally binding agreement
        between you (&#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221;) and TERRIFIC
        INNOVATION LTD (&#8220;TERRIFIC INNOVATION LTD&#8221;, &#8220;we&#8221;, &#8220;us&#8221; or
        &#8220;our&#8221;). If you are entering into this agreement on behalf of a business or other
        legal entity, you represent that you have the authority to bind such entity to this
        agreement, in which case the terms &#8220;User&#8221;, &#8220;you&#8221; or
        &#8220;your&#8221; shall refer to such entity. If you do not have such authority, or if you
        do not agree with the terms of this agreement, you must not accept this agreement and may
        not access and use the Website and Services. You should read this Policy so you can
        understand the types of cookies we use, the information we collect using cookies and how
        that information is used. It also describes the choices available to you regarding accepting
        or declining the use of cookies. For further information on how we use, store and keep your
        personal data secure, see our <a href="https://www.terrific.live/legal">privacy policy</a>.
      </p>
      <div class="wpembed-index">
        <h3>Table of contents</h3>
        <ol class="wpembed-index">
          <li><a href="#what-are-cookies">What are cookies?</a></li>
          <li><a href="#what-type-of-cookies-do-we-use">What type of cookies do we use?</a></li>
          <li>
            <a href="#do-we-use-web-beacons-or-tracking-pixels"
              >Do we use web beacons or tracking pixels?</a
            >
          </li>
          <li><a href="#what-are-your-cookie-options">What are your cookie options?</a></li>
          <li><a href="#changes-and-amendments">Changes and amendments</a></li>
          <li><a href="#acceptance-of-this-policy">Acceptance of this policy</a></li>
          <li><a href="#contacting-us">Contacting us</a></li>
        </ol>
      </div>
      <h2 id="what-are-cookies">What are cookies?</h2>
      <p>
        Cookies are small pieces of data stored in text files that are saved on your computer or
        other devices when websites are loaded in a browser. They are widely used to remember you
        and your preferences, either for a single visit (through a &#8220;session cookie&#8221;) or
        for multiple repeat visits (using a &#8220;persistent cookie&#8221;).
      </p>
      <p>
        Session cookies are temporary cookies that are used during the course of your visit to the
        Website, and they expire when you close the web browser.
      </p>
      <p>
        Persistent cookies are used to remember your preferences within our Website and remain on
        your desktop or mobile device even after you close your browser or restart your computer.
        They ensure a consistent and efficient experience for you while visiting the Website and
        Services.
      </p>
      <p>
        Cookies may be set by the Website (&#8220;first-party cookies&#8221;), or by third parties,
        such as those who serve content or provide advertising or analytics services on the Website
        (&#8220;third party cookies&#8221;). These third parties can recognize you when you visit
        our website and also when you visit certain other websites.
      </p>
      <h2 id="what-type-of-cookies-do-we-use">What type of cookies do we use?</h2>
      <h3>Necessary cookies</h3>
      <p>
        Necessary cookies allow us to offer you the best possible experience when accessing and
        navigating through our Website and using its features. For example, these cookies let us
        recognize that you have created an account and have logged into that account to access the
        content.
      </p>
      <h3>Functionality cookies</h3>
      <p>
        Functionality cookies let us operate the Website and Services in accordance with the choices
        you make. For example, we will recognize your username and remember how you customized the
        Website and Services during future visits.
      </p>
      <h3>Analytical cookies</h3>
      <p>
        These cookies enable us and third party services to collect aggregated data for statistical
        purposes on how our visitors use the Website. These cookies do not contain personal
        information such as names and email addresses and are used to help us improve your user
        experience of the Website.
      </p>
      <h3>Advertising cookies</h3>
      <p>
        Advertising cookies allow us and third parties serve relevant ads to you more effectively
        and help us collect aggregated audit data, research, and performance reporting for
        advertisers. They also enable us to understand and improve the delivery of ads to you and
        know when certain ads have been shown to you.
      </p>
      <p>
        Your web browser may request advertisements directly from ad network servers, these networks
        can view, edit, or set their own cookies, just as if you had requested a web page from their
        website.
      </p>
      <p>
        Although we do not use cookies to create a profile of your browsing behavior on third party
        websites, we do use aggregate data from third parties to show you relevant, interest-based
        advertising.
      </p>
      <h3>Social media cookies</h3>
      <p>
        Third party cookies from social media sites (such as Facebook, Twitter, etc) let us track
        social network users when they visit or use the Website and Services, or share content, by
        using a tagging mechanism provided by those social networks.
      </p>
      <p>
        These cookies are also used for event tracking and remarketing purposes. Any data collected
        with these tags will be used in accordance with our and social networks&#8217; privacy
        policies. We will not collect or share any personally identifiable information from the
        user.
      </p>
      <h2 id="do-we-use-web-beacons-or-tracking-pixels">
        Do we use web beacons or tracking pixels?
      </h2>
      <p>
        Our emails may contain a &#8220;web beacon&#8221; (or &#8220;tracking pixel&#8221;) to tell
        us whether our emails are opened and verify any clicks through to links or advertisements
        within the email.
      </p>
      <p>
        We may use this information for purposes including determining which of our emails are more
        interesting to users and to query whether users who do not open our emails wish to continue
        receiving them.
      </p>
      <p>
        The pixel will be deleted when you delete the email. If you do not wish the pixel to be
        downloaded to your device, you should read the email in plain text view or with images
        disabled.
      </p>
      <h2 id="what-are-your-cookie-options">What are your cookie options?</h2>
      <p>
        If you don&#8217;t like the idea of cookies or certain types of cookies, you can change your
        browser&#8217;s settings to delete cookies that have already been set and to not accept new
        cookies. Visit
        <a href="https://www.internetcookies.com" target="_blank">internetcookies.com</a> to learn
        more about how to do this.
      </p>
      <p>
        Please note, however, that if you delete cookies or do not accept them, you might not be
        able to use all of the features the Website and Services offer.
      </p>
      <h2 id="changes-and-amendments">Changes and amendments</h2>
      <p>
        We reserve the right to modify this Policy or its terms related to the Website and Services
        at any time at our discretion. When we do, we will revise the updated date at the bottom of
        this page. We may also provide notice to you in other ways at our discretion, such as
        through the contact information you have provided.
      </p>
      <p>
        An updated version of this Policy will be effective immediately upon the posting of the
        revised Policy unless otherwise specified. Your continued use of the Website and Services
        after the effective date of the revised Policy (or such other act specified at that time)
        will constitute your consent to those changes.
      </p>
      <h2 id="acceptance-of-this-policy">Acceptance of this policy</h2>
      <p>
        You acknowledge that you have read this Policy and agree to all its terms and conditions. By
        accessing and using the Website and Services you agree to be bound by this Policy. If you do
        not agree to abide by the terms of this Policy, you are not authorized to access or use the
        Website and Services.
      </p>
      <h2 id="contacting-us">Contacting us</h2>
      <p>
        If you have any questions, concerns, or complaints regarding this Policy or the use of
        cookies, we encourage you to contact us using the details below:
      </p>
      <p>
        <a href="https://www.terrific.live/contact/">https://www.terrific.live/contact/</a><br /><a
          href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#105;&#110;f&#111;&#64;t&#101;&#114;rifi&#99;&#108;&#105;&#118;&#101;.&#99;&#111;m"
          >in&#102;o&#64;&#116;&#101;&#114;&#114;if&#105;&#99;&#108;ive&#46;&#99;om</a
        >
      </p>
      <p>This document was last updated on November 8, 2022</p>`,
  },
  {
    title: 'Disclaimer',
    htmlContent: `<h1>Disclaimer</h1>
      <p>
        This disclaimer (&#8220;Disclaimer&#8221;) sets forth the general guidelines, disclosures,
        and terms of your use of the
        <a href="http://terrific.live">terrific.live</a> website (&#8220;Website&#8221; or
        &#8220;Service&#8221;) and any of its related products and services (collectively,
        &#8220;Services&#8221;). This Disclaimer is a legally binding agreement between you
        (&#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221;) and TERRIFIC INNOVATION LTD
        (&#8220;TERRIFIC INNOVATION LTD&#8221;, &#8220;we&#8221;, &#8220;us&#8221; or
        &#8220;our&#8221;). If you are entering into this agreement on behalf of a business or other
        legal entity, you represent that you have the authority to bind such entity to this
        agreement, in which case the terms &#8220;User&#8221;, &#8220;you&#8221; or
        &#8220;your&#8221; shall refer to such entity. If you do not have such authority, or if you
        do not agree with the terms of this agreement, you must not accept this agreement and may
        not access and use the Website and Services. By accessing and using the Website and
        Services, you acknowledge that you have read, understood, and agree to be bound by the terms
        of this Disclaimer. You acknowledge that this Disclaimer is a contract between you and
        TERRIFIC INNOVATION LTD, even though it is electronic and is not physically signed by you,
        and it governs your use of the Website and Services.
      </p>
      <div class="wpembed-index">
        <h3>Table of contents</h3>
        <ol class="wpembed-index">
          <li><a href="#representation">Representation</a></li>
          <li><a href="#content-and-postings">Content and postings</a></li>
          <li><a href="#compensation-and-sponsorship">Compensation and sponsorship</a></li>
          <li><a href="#reviews-and-testimonials">Reviews and testimonials</a></li>
          <li><a href="#indemnification-and-warranties">Indemnification and warranties</a></li>
          <li><a href="#changes-and-amendments">Changes and amendments</a></li>
          <li><a href="#acceptance-of-this-disclaimer">Acceptance of this disclaimer</a></li>
          <li><a href="#contacting-us">Contacting us</a></li>
        </ol>
      </div>
      <h2 id="representation">Representation</h2>
      <p>
        Any views or opinions represented on the Website belong solely to the content creators and
        do not represent those of people, institutions or organizations that TERRIFIC INNOVATION LTD
        or creators may or may not be associated with in professional or personal capacity, unless
        explicitly stated. Any views or opinions are not intended to malign any religion, ethnic
        group, club, organization, company, or individual.
      </p>
      <h2 id="content-and-postings">Content and postings</h2>
      <p>
        You may not modify, print or copy any part of the Website and Services. Inclusion of any
        part of the Website and Services in another work, whether in printed or electronic or
        another form or inclusion of any part of the Website and Services on another resource by
        embedding, framing or otherwise without the express permission of TERRIFIC INNOVATION LTD is
        prohibited.
      </p>
      <p>
        You may submit new content and comment on the existing content on the Website. By uploading
        or otherwise making available any information to TERRIFIC INNOVATION LTD, you grant TERRIFIC
        INNOVATION LTD the unlimited, perpetual right to distribute, display, publish, reproduce,
        reuse and copy the information contained therein. You may not impersonate any other person
        through the Website and Services. You may not post content that is defamatory, fraudulent,
        obscene, threatening, invasive of another person&#8217;s privacy rights or that is otherwise
        unlawful. You may not post content that infringes on the intellectual property rights of any
        other person or entity. You may not post any content that includes any computer virus or
        other code designed to disrupt, damage, or limit the functioning of any computer software or
        hardware.
      </p>
      <h2 id="compensation-and-sponsorship">Compensation and sponsorship</h2>
      <p>
        The Website and Services may contain forms of sponsorship, paid insertions or other forms of
        compensation. On certain occasions TERRIFIC INNOVATION LTD may be compensated to provide
        opinions on products, services, or various other topics. The compensation received may
        influence such opinions of the advertised content or topics available on the Website.
        Sponsored content and advertising space will always be identified as such. Some of the links
        on the Website may be affiliate links. This means if you click on the link and purchase an
        item, TERRIFIC INNOVATION LTD will receive an affiliate commission.
      </p>
      <h2 id="reviews-and-testimonials">Reviews and testimonials</h2>
      <p>
        Testimonials are received in various forms through a variety of submission methods. The
        testimonials are not necessarily representative of all of those who will use Website and
        Services, and TERRIFIC INNOVATION LTD is not responsible for the opinions or comments
        available on the Website, and does not necessarily share them. People providing testimonials
        on the Website may have been compensated with free products or discounts for use of their
        experiences. All opinions expressed are strictly the views of the reviewers.
      </p>
      <p>
        The testimonials displayed are given verbatim except for grammatical or typing error
        corrections. Some testimonials may have been edited for clarity, or shortened in cases where
        the original testimonial included extraneous information of no relevance to the general
        public. Testimonials may be reviewed for authenticity before they are available for public
        viewing.
      </p>
      <h2 id="indemnification-and-warranties">Indemnification and warranties</h2>
      <p>
        While we have made every attempt to ensure that the information contained on the Website is
        correct, TERRIFIC INNOVATION LTD is not responsible for any errors or omissions, or for the
        results obtained from the use of this information. All information on the Website is
        provided &#8220;as is&#8221;, with no guarantee of completeness, accuracy, timeliness or of
        the results obtained from the use of this information, and without warranty of any kind,
        express or implied. In no event will TERRIFIC INNOVATION LTD, or its partners, employees or
        agents, be liable to you or anyone else for any decision made or action taken in reliance on
        the information on the Website, or for any consequential, special or similar damages, even
        if advised of the possibility of such damages. Information on the Website is for general
        information purposes only and is not intended to provide any type of professional advice.
        Please seek professional assistance should you require it. Information contained on the
        Website are subject to change at any time and without warning.
      </p>
      <h2 id="changes-and-amendments">Changes and amendments</h2>
      <p>
        We reserve the right to modify this Disclaimer or its terms related to the Website and
        Services at any time at our discretion. When we do, we will revise the updated date at the
        bottom of this page. We may also provide notice to you in other ways at our discretion, such
        as through the contact information you have provided.
      </p>
      <p>
        An updated version of this Disclaimer will be effective immediately upon the posting of the
        revised Disclaimer unless otherwise specified. Your continued use of the Website and
        Services after the effective date of the revised Disclaimer (or such other act specified at
        that time) will constitute your consent to those changes.
      </p>
      <h2 id="acceptance-of-this-disclaimer">Acceptance of this disclaimer</h2>
      <p>
        You acknowledge that you have read this Disclaimer and agree to all its terms and
        conditions. By accessing and using the Website and Services you agree to be bound by this
        Disclaimer. If you do not agree to abide by the terms of this Disclaimer, you are not
        authorized to access or use the Website and Services.
      </p>
      <h2 id="contacting-us">Contacting us</h2>
      <p>
        If you have any questions, concerns, or complaints regarding this Disclaimer, we encourage
        you to contact us using the details below:
      </p>
      <p>
        <a
          href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;i&#110;&#102;&#111;&#64;&#116;e&#114;ri&#102;&#105;&#99;li&#118;&#101;&#46;&#99;om"
          >&#105;&#110;f&#111;&#64;&#116;&#101;rr&#105;fic&#108;&#105;&#118;&#101;&#46;c&#111;m</a
        >
      </p>
      <p>This document was last updated on November 8, 2022</p></ng-template
    >`,
  },
  {
    title: 'Privacy policy',
    htmlContent: `<h1>Privacy policy</h1>
      <p>
        We respect your privacy and are committed to protecting it through our compliance with this
        privacy policy (&#8220;Policy&#8221;). This Policy describes the types of information we may
        collect from you or that you may provide (&#8220;Personal Information&#8221;) on the
        <a href="http://terrific.live">terrific.live</a> website (&#8220;Website&#8221; or
        &#8220;Service&#8221;) and any of its related products and services (collectively,
        &#8220;Services&#8221;), and our practices for collecting, using, maintaining, protecting,
        and disclosing that Personal Information. It also describes the choices available to you
        regarding our use of your Personal Information and how you can access and update it.
      </p>
      <p>
        This Policy is a legally binding agreement between you (&#8220;User&#8221;,
        &#8220;you&#8221; or &#8220;your&#8221;) and TERRIFIC INNOVATION LTD (&#8220;TERRIFIC
        INNOVATION LTD&#8221;, &#8220;we&#8221;, &#8220;us&#8221; or &#8220;our&#8221;). If you are
        entering into this agreement on behalf of a business or other legal entity, you represent
        that you have the authority to bind such entity to this agreement, in which case the terms
        &#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221; shall refer to such entity. If
        you do not have such authority, or if you do not agree with the terms of this agreement, you
        must not accept this agreement and may not access and use the Website and Services. By
        accessing and using the Website and Services, you acknowledge that you have read,
        understood, and agree to be bound by the terms of this Policy. This Policy does not apply to
        the practices of companies that we do not own or control, or to individuals that we do not
        employ or manage.
      </p>
      <div class="wpembed-index">
        <h3>Table of contents</h3>
        <ol class="wpembed-index">
          <li>
            <a href="#automatic-collection-of-information">Automatic collection of information</a>
          </li>
          <li>
            <a href="#collection-of-personal-information">Collection of personal information</a>
          </li>
          <li><a href="#privacy-of-children">Privacy of children</a></li>
          <li>
            <a href="#use-and-processing-of-collected-information"
              >Use and processing of collected information</a
            >
          </li>
          <li><a href="#payment-processing">Payment processing</a></li>
          <li><a href="#managing-information">Managing information</a></li>
          <li><a href="#disclosure-of-information">Disclosure of information</a></li>
          <li><a href="#retention-of-information">Retention of information</a></li>
          <li><a href="#transfer-of-information">Transfer of information</a></li>
          <li>
            <a href="#data-protection-rights-under-the-gdpr"
              >Data protection rights under the GDPR</a
            >
          </li>
          <li><a href="#california-privacy-rights">California privacy rights</a></li>
          <li><a href="#how-to-exercise-your-rights">How to exercise your rights</a></li>
          <li><a href="#cookies">Cookies</a></li>
          <li><a href="#data-analytics">Data analytics</a></li>
          <li><a href="#do-not-track-signals">Do Not Track signals</a></li>
          <li><a href="#advertisements">Advertisements</a></li>
          <li><a href="#social-media-features">Social media features</a></li>
          <li><a href="#email-marketing">Email marketing</a></li>
          <li><a href="#push-notifications">Push notifications</a></li>
          <li><a href="#affiliate-links">Affiliate links</a></li>
          <li><a href="#links-to-other-resources">Links to other resources</a></li>
          <li><a href="#information-security">Information security</a></li>
          <li><a href="#data-breach">Data breach</a></li>
          <li><a href="#changes-and-amendments">Changes and amendments</a></li>
          <li><a href="#acceptance-of-this-policy">Acceptance of this policy</a></li>
          <li><a href="#contacting-us">Contacting us</a></li>
        </ol>
      </div>
      <h2 id="automatic-collection-of-information">Automatic collection of information</h2>
      <p>
        When you open the Website, our servers automatically record information that your browser
        sends. This data may include information such as your device&#8217;s IP address, browser
        type, and version, operating system type and version, language preferences or the webpage
        you were visiting before you came to the Website and Services, pages of the Website and
        Services that you visit, the time spent on those pages, information you search for on the
        Website, access times and dates, and other statistics.
      </p>
      <p>
        Information collected automatically is used only to identify potential cases of abuse and
        establish statistical information regarding the usage and traffic of the Website and
        Services. This statistical information is not otherwise aggregated in such a way that would
        identify any particular User of the system.
      </p>
      <h2 id="collection-of-personal-information">Collection of personal information</h2>
      <p>
        You can access and use the Website and Services without telling us who you are or revealing
        any information by which someone could identify you as a specific, identifiable individual.
        If, however, you wish to use some of the features offered on the Website, you may be asked
        to provide certain Personal Information (for example, your name and e-mail address).
      </p>
      <p>
        We receive and store any information you knowingly provide to us when you create an account,
        publish content, make a purchase, or fill any forms on the Website. When required, this
        information may include the following:
      </p>
      <ul>
        <li>Account details (such as user name, unique user ID, password, etc)</li>
        <li>Contact information (such as email address, phone number, etc)</li>
        <li>Basic personal information (such as name, country of residence, etc)</li>
        <li>Payment information (such as credit card details, bank details, etc)</li>
        <li>Information about other individuals (such as your family members, friends, etc)</li>
        <li>
          Any other materials you willingly submit to us (such as articles, images, feedback, etc)
        </li>
      </ul>
      <p>
        Some of the information we collect is directly from you via the Website and Services.
        However, we may also collect Personal Information about you from other sources such as
        social media platforms, public databases, third-party data providers, and our joint
        partners. Personal Information we collect from other sources may include demographic
        information, such as age and gender, device information, such as IP addresses, location,
        such as city and state, and online behavioral data, such as information about your use of
        social media websites, page view information and search results and links.
      </p>
      <p>
        You can choose not to provide us with your Personal Information, but then you may not be
        able to take advantage of some of the features on the Website. Users who are uncertain about
        what information is mandatory are welcome to contact us.
      </p>
      <h2 id="privacy-of-children">Privacy of children</h2>
      <p>
        We do not knowingly collect any Personal Information from children under the age of 13. If
        you are under the age of 13, please do not submit any Personal Information through the
        Website and Services. If you have reason to believe that a child under the age of 13 has
        provided Personal Information to us through the Website and Services, please contact us to
        request that we delete that child&#8217;s Personal Information from our Services.
      </p>
      <p>
        We encourage parents and legal guardians to monitor their children&#8217;s Internet usage
        and to help enforce this Policy by instructing their children never to provide Personal
        Information through the Website and Services without their permission. We also ask that all
        parents and legal guardians overseeing the care of children take the necessary precautions
        to ensure that their children are instructed to never give out Personal Information when
        online without their permission.
      </p>
      <h2 id="use-and-processing-of-collected-information">
        Use and processing of collected information
      </h2>
      <p>
        We act as a data controller and a data processor in terms of the GDPR when handling Personal
        Information, unless we have entered into a data processing agreement with you in which case
        you would be the data controller and we would be the data processor.
      </p>
      <p>
        Our role may also differ depending on the specific situation involving Personal Information.
        We act in the capacity of a data controller when we ask you to submit your Personal
        Information that is necessary to ensure your access and use of the Website and Services. In
        such instances, we are a data controller because we determine the purposes and means of the
        processing of Personal Information and we comply with data controllers&#8217; obligations
        set forth in the GDPR.
      </p>
      <p>
        We act in the capacity of a data processor in situations when you submit Personal
        Information through the Website and Services. We do not own, control, or make decisions
        about the submitted Personal Information, and such Personal Information is processed only in
        accordance with your instructions. In such instances, the User providing Personal
        Information acts as a data controller in terms of the GDPR.
      </p>
      <p>
        In order to make the Website and Services available to you, or to meet a legal obligation,
        we may need to collect and use certain Personal Information. If you do not provide the
        information that we request, we may not be able to provide you with the requested products
        or services. Any of the information we collect from you may be used for the following
        purposes:
      </p>
      <ul>
        <li>Create and manage user accounts</li>
        <li>Fulfill and manage orders</li>
        <li>Deliver products or services</li>
        <li>Improve products and services</li>
        <li>Send administrative information</li>
        <li>Send marketing and promotional communications</li>
        <li>Send product and service updates</li>
        <li>Respond to inquiries and offer support</li>
        <li>Request user feedback</li>
        <li>Improve user experience</li>
        <li>Post customer testimonials</li>
        <li>Enforce terms and conditions and policies</li>
        <li>Protect from abuse and malicious users</li>
        <li>Respond to legal requests and prevent harm</li>
        <li>Run and operate the Website and Services</li>
      </ul>
      <p>
        Processing your Personal Information depends on how you interact with the Website and
        Services, where you are located in the world and if one of the following applies: (i) you
        have given your consent for one or more specific purposes; this, however, does not apply,
        whenever the processing of Personal Information is subject to California Consumer Privacy
        Act or European data protection law; (ii) provision of information is necessary for the
        performance of an agreement with you and/or for any pre-contractual obligations thereof;
        (iii) processing is necessary for compliance with a legal obligation to which you are
        subject; (iv) processing is related to a task that is carried out in the public interest or
        in the exercise of official authority vested in us; (v) processing is necessary for the
        purposes of the legitimate interests pursued by us or by a third party. We may also combine
        or aggregate some of your Personal Information in order to better serve you and to improve
        and update our Website and Services.
      </p>
      <p>
        We rely on the following legal bases as defined in the GDPR upon which we collect and
        process your Personal Information:
      </p>
      <ul>
        <li>User&#8217;s consent</li>
        <li>Our own legitimate interests</li>
        <li>Research or statistics for the benefit of the public</li>
      </ul>
      <p>
        Note that under some legislations we may be allowed to process information until you object
        to such processing by opting out, without having to rely on consent or any other of the
        legal bases above. In any case, we will be happy to clarify the specific legal basis that
        applies to the processing, and in particular whether the provision of Personal Information
        is a statutory or contractual requirement, or a requirement necessary to enter into a
        contract.
      </p>
      <h2 id="payment-processing">Payment processing</h2>
      <p>
        In case of Services requiring payment, you may need to provide your credit card details or
        other payment account information, which will be used solely for processing payments. We use
        third-party payment processors (&#8220;Payment Processors&#8221;) to assist us in processing
        your payment information securely.
      </p>
      <p>
        Payment Processors adhere to the latest security standards as managed by the PCI Security
        Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express
        and Discover. Sensitive and private data exchange happens over a SSL secured communication
        channel and is encrypted and protected with digital signatures, and the Website and Services
        are also in compliance with strict vulnerability standards in order to create as secure of
        an environment as possible for Users. We will share payment data with the Payment Processors
        only to the extent necessary for the purposes of processing your payments, refunding such
        payments, and dealing with complaints and queries related to such payments and refunds.
      </p>
      <p>
        Please note that the Payment Processors may collect some Personal Information from you,
        which allows them to process your payments (e.g., your email address, address, credit card
        details, and bank account number) and handle all the steps in the payment process through
        their systems, including data collection and data processing. Where necessary for processing
        future or recurring payments and subject to your prior consent, your financial information
        will be stored in encrypted form on secure servers of our Payment Processors. The Payment
        Processors&#8217; use of your Personal Information is governed by their respective privacy
        policies which may or may not contain privacy protections as protective as this Policy. We
        suggest that you review their respective privacy policies.
      </p>
      <h2 id="managing-information">Managing information</h2>
      <p>
        You are able to delete certain Personal Information we have about you. The Personal
        Information you can delete may change as the Website and Services change. When you delete
        Personal Information, however, we may maintain a copy of the unrevised Personal Information
        in our records for the duration necessary to comply with our obligations to our affiliates
        and partners, and for the purposes described below.
      </p>
      <h2 id="disclosure-of-information">Disclosure of information</h2>
      <p>
        Depending on the requested Services or as necessary to complete any transaction or provide
        any Service you have requested, we may share your information with our affiliates,
        contracted companies, and service providers (collectively, &#8220;Service Providers&#8221;)
        we rely upon to assist in the operation of the Website and Services available to you and
        whose privacy policies are consistent with ours or who agree to abide by our policies with
        respect to Personal Information. We will not share any personally identifiable information
        with third parties and will not share any information with unaffiliated third parties.
      </p>
      <p>
        Service Providers are not authorized to use or disclose your information except as necessary
        to perform services on our behalf or comply with legal requirements. Service Providers are
        given the information they need only in order to perform their designated functions, and we
        do not authorize them to use or disclose any of the provided information for their own
        marketing or other purposes.
      </p>
      <p>
        We may also disclose any Personal Information we collect, use or receive if required or
        permitted by law, such as to comply with a subpoena or similar legal process, and when we
        believe in good faith that disclosure is necessary to protect our rights, protect your
        safety or the safety of others, investigate fraud, or respond to a government request.
      </p>
      <p>
        In the event we go through a business transition, such as a merger or acquisition by another
        company, or sale of all or a portion of its assets, your user account, and your Personal
        Information will likely be among the assets transferred.
      </p>
      <h2 id="retention-of-information">Retention of information</h2>
      <p>
        We will retain and use your Personal Information for the period necessary to comply with our
        legal obligations, until the purchase order or Services are fulfilled, until our and our
        affiliates and partners obligations are fulfilled, to enforce our agreements, resolve
        disputes, and unless a longer retention period is required or permitted by law.
      </p>
      <p>
        We may use any aggregated data derived from or incorporating your Personal Information after
        you update or delete it, but not in a manner that would identify you personally. Once the
        retention period expires, Personal Information shall be deleted. Therefore, the right to
        access, the right to erasure, the right to rectification, and the right to data portability
        cannot be enforced after the expiration of the retention period.
      </p>
      <h2 id="transfer-of-information">Transfer of information</h2>
      <p>
        Depending on your location, data transfers may involve transferring and storing your
        information in a country other than your own, including USA; ISRAEL. The transfer of your
        Personal Information to countries outside the European Union will be made only if you have
        explicitly consented to it or in the cases provided for by the GDPR and will be processed in
        your interest.
      </p>
      <p>
        You are entitled to learn about the legal basis of information transfers to a country
        outside the European Union or to any international organization governed by public
        international law or set up by two or more countries, such as the UN, and about the security
        measures taken by us to safeguard your information. If any such transfer takes place, you
        can find out more by checking the relevant sections of this Policy or inquire with us using
        the information provided in the contact section.
      </p>
      <h2 id="data-protection-rights-under-the-gdpr">Data protection rights under the GDPR</h2>
      <p>
        If you are a resident of the European Economic Area (&#8220;EEA&#8221;), you have certain
        data protection rights and we aim to take reasonable steps to allow you to correct, amend,
        delete, or limit the use of your Personal Information. If you wish to be informed what
        Personal Information we hold about you and if you want it to be removed from our systems,
        please contact us. In certain circumstances, you have the following data protection rights:
      </p>
      <p>
        (i) You have the right to withdraw consent where you have previously given your consent to
        the processing of your Personal Information. To the extent that the legal basis for our
        processing of your Personal Information is consent, you have the right to withdraw that
        consent at any time. Withdrawal will not affect the lawfulness of processing before the
        withdrawal.
      </p>
      <p>
        (ii) You have the right to learn if your Personal Information is being processed by us,
        obtain disclosure regarding certain aspects of the processing, and obtain a copy of your
        Personal Information undergoing processing.
      </p>
      <p>
        (iii) You have the right to verify the accuracy of your information and ask for it to be
        updated or corrected. You also have the right to request us to complete the Personal
        Information you believe is incomplete.
      </p>
      <p>
        (iv) You have the right to object to the processing of your information if the processing is
        carried out on a legal basis other than consent. Where Personal Information is processed for
        the public interest, in the exercise of an official authority vested in us, or for the
        purposes of the legitimate interests pursued by us, you may object to such processing by
        providing a ground related to your particular situation to justify the objection. You must
        know that, however, should your Personal Information be processed for direct marketing
        purposes, you can object to that processing at any time without providing any justification.
        To learn whether we are processing Personal Information for direct marketing purposes, you
        may refer to the relevant sections of this Policy.
      </p>
      <p>
        (v) You have the right, under certain circumstances, to restrict the processing of your
        Personal Information. These circumstances include: the accuracy of your Personal Information
        is contested by you and we must verify its accuracy; the processing is unlawful, but you
        oppose the erasure of your Personal Information and request the restriction of its use
        instead; we no longer need your Personal Information for the purposes of processing, but you
        require it to establish, exercise or defend your legal claims; you have objected to
        processing pending the verification of whether our legitimate grounds override your
        legitimate grounds. Where processing has been restricted, such Personal Information will be
        marked accordingly and, with the exception of storage, will be processed only with your
        consent or for the establishment, to exercise or defense of legal claims, for the protection
        of the rights of another natural, or legal person or for reasons of important public
        interest.
      </p>
      <p>
        (vi) You have the right, under certain circumstances, to obtain the erasure of your Personal
        Information from us. These circumstances include: the Personal Information is no longer
        necessary in relation to the purposes for which it was collected or otherwise processed; you
        withdraw consent to consent-based processing; you object to the processing under certain
        rules of applicable data protection law; the processing is for direct marketing purposes;
        and the personal data have been unlawfully processed. However, there are exclusions of the
        right to erasure such as where processing is necessary: for exercising the right of freedom
        of expression and information; for compliance with a legal obligation; or for the
        establishment, to exercise or defense of legal claims.
      </p>
      <p>
        (vii) You have the right to receive your Personal Information that you have provided to us
        in a structured, commonly used, and machine-readable format and, if technically feasible, to
        have it transmitted to another controller without any hindrance from us, provided that such
        transmission does not adversely affect the rights and freedoms of others.
      </p>
      <p>
        (viii) You have the right to complain to a data protection authority about our collection
        and use of your Personal Information. If you are not satisfied with the outcome of your
        complaint directly with us, you have the right to lodge a complaint with your local data
        protection authority. For more information, please contact your local data protection
        authority in the EEA. This provision is applicable provided that your Personal Information
        is processed by automated means and that the processing is based on your consent, on a
        contract which you are part of, or on pre-contractual obligations thereof.
      </p>
      <h2 id="california-privacy-rights">California privacy rights</h2>
      <p>
        Consumers residing in California are afforded certain additional rights with respect to
        their Personal Information under the California Consumer Privacy Act (&#8220;CCPA&#8221;).
        If you are a California resident, this section applies to you.
      </p>
      <p>
        As described in this Policy in the information collection section above, we have collected
        the categories of Personal Information listed below in the past twelve (12) months:
      </p>
      <ul>
        <li>Personal identifiers (such as email address, phone number, etc)</li>
        <li>Person&#8217;s characteristics (such as age, gender, etc)</li>
        <li>Audio, visual, electronic, or other types of recordings</li>
        <li>Professional or employment information</li>
        <li>Purchase history</li>
        <li>Geolocation data</li>
      </ul>
      <p>
        In addition to the rights as explained in this Policy, California residents who provide
        Personal Information as defined in the statute to obtain Services for personal, family, or
        household use are entitled to request and obtain from us, once a calendar year, information
        about the categories and specific pieces of Personal Information we have collected and
        disclosed.
      </p>
      <p>
        Furthermore, California residents have the right to request deletion of their Personal
        Information or opt-out of the sale of their Personal Information which may include selling,
        disclosing, or transferring Personal Information to another business or a third party for
        monetary or other valuable consideration. To do so, simply contact us. We will not
        discriminate against you if you exercise your rights under the CCPA.
      </p>
      <h2 id="how-to-exercise-your-rights">How to exercise your rights</h2>
      <p>
        Any requests to exercise your rights can be directed to us through the contact details
        provided in this document. Please note that we may ask you to verify your identity before
        responding to such requests. Your request must provide sufficient information that allows us
        to verify that you are the person you are claiming to be or that you are the authorized
        representative of such person. If we receive your request from an authorized representative,
        we may request evidence that you have provided such an authorized representative with power
        of attorney or that the authorized representative otherwise has valid written authority to
        submit requests on your behalf.
      </p>
      <p>
        You must include sufficient details to allow us to properly understand the request and
        respond to it. We cannot respond to your request or provide you with Personal Information
        unless we first verify your identity or authority to make such a request and confirm that
        the Personal Information relates to you.
      </p>
      <h2 id="cookies">Cookies</h2>
      <p>
        Our Website and Services use &#8220;cookies&#8221; to help personalize your online
        experience. A cookie is a text file that is placed on your hard disk by a web page server.
        Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are
        uniquely assigned to you, and can only be read by a web server in the domain that issued the
        cookie to you. If you choose to decline cookies, you may not be able to fully experience the
        features of the Website and Services.
      </p>
      <p>
        We may use cookies to collect, store, and track information for security and
        personalization, to operate the Website and Services, and for statistical purposes. Please
        note that you have the ability to accept or decline cookies. Most web browsers automatically
        accept cookies by default, but you can modify your browser settings to decline cookies if
        you prefer.
      </p>
      <h2 id="data-analytics">Data analytics</h2>
      <p>
        Our Website and Services may use third-party analytics tools that use cookies, web beacons,
        or other similar information-gathering technologies to collect standard internet activity
        and usage information. The information gathered is used to compile statistical reports on
        User activity such as how often Users visit our Website and Services, what pages they visit
        and for how long, etc. We use the information obtained from these analytics tools to monitor
        the performance and improve our Website and Services. We do not use third-party analytics
        tools to track or to collect any personally identifiable information of our Users and we
        will not associate any information gathered from the statistical reports with any individual
        User.
      </p>
      <h2 id="do-not-track-signals">Do Not Track signals</h2>
      <p>
        Some browsers incorporate a Do Not Track feature that signals to websites you visit that you
        do not want to have your online activity tracked. Tracking is not the same as using or
        collecting information in connection with a website. For these purposes, tracking refers to
        collecting personally identifiable information from consumers who use or visit a website or
        online service as they move across different websites over time. The Website and Services do
        not track its visitors over time and across third-party websites. However, some third-party
        websites may keep track of your browsing activities when they serve you content, which
        enables them to tailor what they present to you. For a description of Do Not Track protocols
        for browsers and mobile devices or to learn more about the choices available to you, visit
        <a href="https://www.internetcookies.com" target="_blank">internetcookies.com</a>
      </p>
      <h2 id="advertisements">Advertisements</h2>
      <p>
        We may permit certain third-party companies to help us tailor advertising that we think may
        be of interest to Users and to collect and use other data about User activities on the
        Website. These companies may deliver ads that might place cookies and otherwise track User
        behavior.
      </p>
      <h2 id="social-media-features">Social media features</h2>
      <p>
        Our Website and Services may include social media features, such as the Facebook and Twitter
        buttons, Share This buttons, etc (collectively, &#8220;Social Media Features&#8221;). These
        Social Media Features may collect your IP address, what page you are visiting on our Website
        and Services, and may set a cookie to enable Social Media Features to function properly.
        Social Media Features are hosted either by their respective providers or directly on our
        Website and Services. Your interactions with these Social Media Features are governed by the
        privacy policy of their respective providers.
      </p>
      <h2 id="email-marketing">Email marketing</h2>
      <p>
        We offer electronic newsletters to which you may voluntarily subscribe at any time. We are
        committed to keeping your e-mail address confidential and will not disclose your email
        address to any third parties except as allowed in the information use and processing section
        or for the purposes of utilizing a third-party provider to send such emails. We will
        maintain the information sent via e-mail in accordance with applicable laws and regulations.
      </p>
      <p>
        In compliance with the CAN-SPAM Act, all e-mails sent from us will clearly state who the
        e-mail is from and provide clear information on how to contact the sender. You may choose to
        stop receiving our newsletter or marketing emails by following the unsubscribe instructions
        included in these emails or by contacting us. However, you will continue to receive
        essential transactional emails.
      </p>
      <h2 id="push-notifications">Push notifications</h2>
      <p>
        We offer push notifications to which you may also voluntarily subscribe at any time. To make
        sure push notifications reach the correct devices, we use a third-party push notifications
        provider who relies on a device token unique to your device which is issued by the operating
        system of your device. While it is possible to access a list of device tokens, they will not
        reveal your identity, your unique device ID, or your contact information to us or our
        third-party push notifications provider. We will maintain the information sent via e-mail in
        accordance with applicable laws and regulations. If, at any time, you wish to stop receiving
        push notifications, simply adjust your device settings accordingly.
      </p>
      <h2 id="affiliate-links">Affiliate links</h2>
      <p>
        We may engage in affiliate marketing and have affiliate links present on the Website and
        Services for the purpose of being able to offer you related or additional products and
        services. If you click on an affiliate link, a cookie will be placed on your browser to
        track any sales for purposes of commissions.
      </p>
      <h2 id="links-to-other-resources">Links to other resources</h2>
      <p>
        The Website and Services contain links to other resources that are not owned or controlled
        by us. Please be aware that we are not responsible for the privacy practices of such other
        resources or third parties. We encourage you to be aware when you leave the Website and
        Services and to read the privacy statements of each and every resource that may collect
        Personal Information.
      </p>
      <h2 id="information-security">Information security</h2>
      <p>
        We secure information you provide on computer servers in a controlled, secure environment,
        protected from unauthorized access, use, or disclosure. We maintain reasonable
        administrative, technical, and physical safeguards in an effort to protect against
        unauthorized access, use, modification, and disclosure of Personal Information in our
        control and custody. However, no data transmission over the Internet or wireless network can
        be guaranteed.
      </p>
      <p>
        Therefore, while we strive to protect your Personal Information, you acknowledge that (i)
        there are security and privacy limitations of the Internet which are beyond our control;
        (ii) the security, integrity, and privacy of any and all information and data exchanged
        between you and the Website and Services cannot be guaranteed; and (iii) any such
        information and data may be viewed or tampered with in transit by a third party, despite
        best efforts.
      </p>
      <p>
        As the security of Personal Information depends in part on the security of the device you
        use to communicate with us and the security you use to protect your credentials, please take
        appropriate measures to protect this information.
      </p>
      <h2 id="data-breach">Data breach</h2>
      <p>
        In the event we become aware that the security of the Website and Services has been
        compromised or Users&#8217; Personal Information has been disclosed to unrelated third
        parties as a result of external activity, including, but not limited to, security attacks or
        fraud, we reserve the right to take reasonably appropriate measures, including, but not
        limited to, investigation and reporting, as well as notification to and cooperation with law
        enforcement authorities. In the event of a data breach, we will make reasonable efforts to
        notify affected individuals if we believe that there is a reasonable risk of harm to the
        User as a result of the breach or if notice is otherwise required by law. When we do, we
        will post a notice on the Website, send you an email.
      </p>
      <h2 id="changes-and-amendments">Changes and amendments</h2>
      <p>
        We reserve the right to modify this Policy or its terms related to the Website and Services
        at any time at our discretion. When we do, we will revise the updated date at the bottom of
        this page. We may also provide notice to you in other ways at our discretion, such as
        through the contact information you have provided.
      </p>
      <p>
        An updated version of this Policy will be effective immediately upon the posting of the
        revised Policy unless otherwise specified. Your continued use of the Website and Services
        after the effective date of the revised Policy (or such other act specified at that time)
        will constitute your consent to those changes. However, we will not, without your consent,
        use your Personal Information in a manner materially different than what was stated at the
        time your Personal Information was collected.
      </p>
      <h2 id="acceptance-of-this-policy">Acceptance of this policy</h2>
      <p>
        You acknowledge that you have read this Policy and agree to all its terms and conditions. By
        accessing and using the Website and Services and submitting your information you agree to be
        bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not
        authorized to access or use the Website and Services.
      </p>
      <h2 id="contacting-us">Contacting us</h2>
      <p>
        If you have any questions regarding the information we may hold about you or if you wish to
        exercise your rights, you may use the following data subject request form to submit your
        request:
      </p>
      <p>
        <a
          href="https://app.websitepolicies.com/dsar/view/c8jnqenn"
          target="_blank"
          rel="nofollow noreferrer noopener external"
          >Submit a data access request</a
        >
      </p>
      <p>
        If you have any other questions, concerns, or complaints regarding this Policy, we encourage
        you to contact us using the details below:
      </p>
      <p>
        <a
          href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;in&#102;o&#64;t&#101;&#114;&#114;&#105;&#102;&#105;c&#108;i&#118;e.&#99;om"
          >&#105;nf&#111;&#64;t&#101;&#114;&#114;&#105;&#102;&#105;&#99;li&#118;e&#46;com</a
        ><br />Shamgar St 21, Jerusalem, ISRAEL
      </p>
      <p>
        We will attempt to resolve complaints and disputes and make every reasonable effort to honor
        your wish to exercise your rights as quickly as possible and in any event, within the
        timescales provided by applicable data protection laws.
      </p>
      <p>This document was last updated on November 8, 2022</p>`,
  },
  {
    title: 'Terms and conditions',
    htmlContent: `<p>
        These terms and conditions (&#8220;Agreement&#8221;) set forth the general terms and
        conditions of your use of the <a href="http://terrific.live">terrific.live</a> website
        (&#8220;Website&#8221; or &#8220;Service&#8221;) and any of its related products and
        services (collectively, &#8220;Services&#8221;). This Agreement is legally binding between
        you (&#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221;) and TERRIFIC INNOVATION
        LTD (&#8220;TERRIFIC INNOVATION LTD&#8221;, &#8220;we&#8221;, &#8220;us&#8221; or
        &#8220;our&#8221;). If you are entering into this agreement on behalf of a business or other
        legal entity, you represent that you have the authority to bind such entity to this
        agreement, in which case the terms &#8220;User&#8221;, &#8220;you&#8221; or
        &#8220;your&#8221; shall refer to such entity. If you do not have such authority, or if you
        do not agree with the terms of this agreement, you must not accept this agreement and may
        not access and use the Website and Services. By accessing and using the Website and
        Services, you acknowledge that you have read, understood, and agree to be bound by the terms
        of this Agreement. You acknowledge that this Agreement is a contract between you and
        TERRIFIC INNOVATION LTD, even though it is electronic and is not physically signed by you,
        and it governs your use of the Website and Services.
      </p>
      <div class="wpembed-index">
        <h3>Table of contents</h3>
        <ol class="wpembed-index">
          <li><a href="#accounts-and-membership">Accounts and membership</a></li>
          <li><a href="#user-content">User content</a></li>
          <li><a href="#billing-and-payments">Billing and payments</a></li>
          <li><a href="#accuracy-of-information">Accuracy of information</a></li>
          <li><a href="#third-party-services">Third party services</a></li>
          <li><a href="#uptime-guarantee">Uptime guarantee</a></li>
          <li><a href="#backups">Backups</a></li>
          <li><a href="#links-to-other-resources">Links to other resources</a></li>
          <li><a href="#prohibited-uses">Prohibited uses</a></li>
          <li><a href="#intellectual-property-rights">Intellectual property rights</a></li>
          <li><a href="#disclaimer-of-warranty">Disclaimer of warranty</a></li>
          <li><a href="#limitation-of-liability">Limitation of liability</a></li>
          <li><a href="#severability">Severability</a></li>
          <li><a href="#dispute-resolution">Dispute resolution</a></li>
          <li><a href="#assignment">Assignment</a></li>
          <li><a href="#changes-and-amendments">Changes and amendments</a></li>
          <li><a href="#acceptance-of-these-terms">Acceptance of these terms</a></li>
          <li><a href="#contacting-us">Contacting us</a></li>
        </ol>
      </div>
      <h2 id="accounts-and-membership">Accounts and membership</h2>
      <p>
        You must be at least 13 years of age to use the Website and Services. By using the Website
        and Services and by agreeing to this Agreement you warrant and represent that you are at
        least 13 years of age. If you create an account on the Website, you are responsible for
        maintaining the security of your account and you are fully responsible for all activities
        that occur under the account and any other actions taken in connection with it. We may, but
        have no obligation to, monitor and review new accounts before you may sign in and start
        using the Services. Providing false contact information of any kind may result in the
        termination of your account. You must immediately notify us of any unauthorized uses of your
        account or any other breaches of security. We will not be liable for any acts or omissions
        by you, including any damages of any kind incurred as a result of such acts or omissions. We
        may suspend, disable, or delete your account (or any part thereof) if we determine that you
        have violated any provision of this Agreement or that your conduct or content would tend to
        damage our reputation and goodwill. If we delete your account for the foregoing reasons, you
        may not re-register for our Services. We may block your email address and Internet protocol
        address to prevent further registration.
      </p>
      <h2 id="user-content">User content</h2>
      <p>
        We do not own any data, information or material (collectively, &#8220;Content&#8221;) that
        you submit on the Website in the course of using the Service. You shall have sole
        responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness,
        and intellectual property ownership or right to use of all submitted Content. We may, but
        have no obligation to, monitor and review the Content on the Website submitted or created
        using our Services by you. You grant us permission to access, copy, distribute, store,
        transmit, reformat, display and perform the Content of your user account solely as required
        for the purpose of providing the Services to you. Without limiting any of those
        representations or warranties, we have the right, though not the obligation, to, in our own
        sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any
        of our policies or is in any way harmful or objectionable. You also grant us the license to
        use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in
        your user account for commercial, marketing or any similar purpose.
      </p>
      <h2 id="billing-and-payments">Billing and payments</h2>
      <p>
        You shall pay all fees or charges to your account in accordance with the fees, charges, and
        billing terms in effect at the time a fee or charge is due and payable. Where Services are
        offered on a free trial basis, payment may be required after the free trial period ends, and
        not when you enter your billing details (which may be required prior to the commencement of
        the free trial period). If auto-renewal is enabled for the Services you have subscribed for,
        you will be charged automatically in accordance with the term you selected. If, in our
        judgment, your purchase constitutes a high-risk transaction, we will require you to provide
        us with a copy of your valid government-issued photo identification, and possibly a copy of
        a recent bank statement for the credit or debit card used for the purchase. We reserve the
        right to change products and product pricing at any time. We also reserve the right to
        refuse any order you place with us. We may, in our sole discretion, limit or cancel
        quantities purchased per person, per household or per order. These restrictions may include
        orders placed by or under the same customer account, the same credit card, and/or orders
        that use the same billing and/or shipping address. In the event that we make a change to or
        cancel an order, we may attempt to notify you by contacting the e-mail and/or billing
        address/phone number provided at the time the order was made.
      </p>
      <h2 id="accuracy-of-information">Accuracy of information</h2>
      <p>
        Occasionally there may be information on the Website that contains typographical errors,
        inaccuracies or omissions that may relate to availability, promotions and offers. We reserve
        the right to correct any errors, inaccuracies or omissions, and to change or update
        information or cancel orders if any information on the Website or Services is inaccurate at
        any time without prior notice (including after you have submitted your order). We undertake
        no obligation to update, amend or clarify information on the Website including, without
        limitation, pricing information, except as required by law. No specified update or refresh
        date applied on the Website should be taken to indicate that all information on the Website
        or Services has been modified or updated.
      </p>
      <h2 id="third-party-services">Third party services</h2>
      <p>
        If you decide to enable, access or use third party services, be advised that your access and
        use of such other services are governed solely by the terms and conditions of such other
        services, and we do not endorse, are not responsible or liable for, and make no
        representations as to any aspect of such other services, including, without limitation,
        their content or the manner in which they handle data (including your data) or any
        interaction between you and the provider of such other services. You irrevocably waive any
        claim against TERRIFIC INNOVATION LTD with respect to such other services. TERRIFIC
        INNOVATION LTD is not liable for any damage or loss caused or alleged to be caused by or in
        connection with your enablement, access or use of any such other services, or your reliance
        on the privacy practices, data security processes or other policies of such other services.
        You may be required to register for or log into such other services on their respective
        platforms. By enabling any other services, you are expressly permitting TERRIFIC INNOVATION
        LTD to disclose your data as necessary to facilitate the use or enablement of such other
        service.
      </p>
      <h2 id="uptime-guarantee">Uptime guarantee</h2>
      <p>
        We offer a Service uptime guarantee of 99% of available time per month. The service uptime
        guarantee does not apply to service interruptions caused by: (1) periodic scheduled
        maintenance or repairs we may undertake from time to time; (2) interruptions caused by you
        or your activities; (3) outages that do not affect core Service functionality; (4) causes
        beyond our control or that are not reasonably foreseeable; and (5) outages related to the
        reliability of certain programming environments.
      </p>
      <h2 id="backups">Backups</h2>
      <p>
        We perform regular backups of the Website and its Content and will do our best to ensure
        completeness and accuracy of these backups. In the event of the hardware failure or data
        loss we will restore backups automatically to minimize the impact and downtime.
      </p>
      <h2 id="links-to-other-resources">Links to other resources</h2>
      <p>
        Although the Website and Services may link to other resources (such as websites, mobile
        applications, etc.), we are not, directly or indirectly, implying any approval, association,
        sponsorship, endorsement, or affiliation with any linked resource, unless specifically
        stated herein. Some of the links on the Website may be &#8220;affiliate links&#8221;. This
        means if you click on the link and purchase an item, TERRIFIC INNOVATION LTD will receive an
        affiliate commission. We are not responsible for examining or evaluating, and we do not
        warrant the offerings of, any businesses or individuals or the content of their resources.
        We do not assume any responsibility or liability for the actions, products, services, and
        content of any other third parties. You should carefully review the legal statements and
        other conditions of use of any resource which you access through a link on the Website. Your
        linking to any other off-site resources is at your own risk.
      </p>
      <h2 id="prohibited-uses">Prohibited uses</h2>
      <p>
        In addition to other terms as set forth in the Agreement, you are prohibited from using the
        Website and Services or Content: (a) for any unlawful purpose; (b) to solicit others to
        perform or participate in any unlawful acts; (c) to violate any international, federal,
        provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or
        violate our intellectual property rights or the intellectual property rights of others; (e)
        to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate
        based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or
        disability; (f) to submit false or misleading information; (g) to upload or transmit viruses
        or any other type of malicious code that will or may be used in any way that will affect the
        functionality or operation of the Website and Services, third party products and services,
        or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any
        obscene or immoral purpose; or (j) to interfere with or circumvent the security features of
        the Website and Services, third party products and services, or the Internet. We reserve the
        right to terminate your use of the Website and Services for violating any of the prohibited
        uses.
      </p>
      <h2 id="intellectual-property-rights">Intellectual property rights</h2>
      <p>
        &#8220;Intellectual Property Rights&#8221; means all present and future rights conferred by
        statute, common law or equity in or in relation to any copyright and related rights,
        trademarks, designs, patents, inventions, goodwill and the right to sue for passing off,
        rights to inventions, rights to use, and all other intellectual property rights, in each
        case whether registered or unregistered and including all applications and rights to apply
        for and be granted, rights to claim priority from, such rights and all similar or equivalent
        rights or forms of protection and any other results of intellectual activity which subsist
        or will subsist now or in the future in any part of the world. This Agreement does not
        transfer to you any intellectual property owned by TERRIFIC INNOVATION LTD or third parties,
        and all rights, titles, and interests in and to such property will remain (as between the
        parties) solely with TERRIFIC INNOVATION LTD. All trademarks, service marks, graphics and
        logos used in connection with the Website and Services, are trademarks or registered
        trademarks of TERRIFIC INNOVATION LTD or its licensors. Other trademarks, service marks,
        graphics and logos used in connection with the Website and Services may be the trademarks of
        other third parties. Your use of the Website and Services grants you no right or license to
        reproduce or otherwise use any of TERRIFIC INNOVATION LTD or third party trademarks.
      </p>
      <h2 id="disclaimer-of-warranty">Disclaimer of warranty</h2>
      <p>
        You agree that such Service is provided on an &#8220;as is&#8221; and &#8220;as
        available&#8221; basis and that your use of the Website and Services is solely at your own
        risk. We expressly disclaim all warranties of any kind, whether express or implied,
        including but not limited to the implied warranties of merchantability, fitness for a
        particular purpose and non-infringement. We make no warranty that the Services will meet
        your requirements, or that the Service will be uninterrupted, timely, secure, or error-free;
        nor do we make any warranty as to the results that may be obtained from the use of the
        Service or as to the accuracy or reliability of any information obtained through the Service
        or that defects in the Service will be corrected. You understand and agree that any material
        and/or data downloaded or otherwise obtained through the use of Service is done at your own
        discretion and risk and that you will be solely responsible for any damage or loss of data
        that results from the download of such material and/or data. We make no warranty regarding
        any goods or services purchased or obtained through the Service or any transactions entered
        into through the Service unless stated otherwise. No advice or information, whether oral or
        written, obtained by you from us or through the Service shall create any warranty not
        expressly made herein.
      </p>
      <h2 id="limitation-of-liability">Limitation of liability</h2>
      <p>
        To the fullest extent permitted by applicable law, in no event will TERRIFIC INNOVATION LTD,
        its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to
        any person for any indirect, incidental, special, punitive, cover or consequential damages
        (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of
        content, impact on business, business interruption, loss of anticipated savings, loss of
        business opportunity) however caused, under any theory of liability, including, without
        limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise,
        even if the liable party has been advised as to the possibility of such damages or could
        have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate
        liability of TERRIFIC INNOVATION LTD and its affiliates, officers, employees, agents,
        suppliers and licensors relating to the services will be limited to an amount no greater
        than one dollar or any amounts actually paid in cash by you to TERRIFIC INNOVATION LTD for
        the prior one month period prior to the first event or occurrence giving rise to such
        liability. The limitations and exclusions also apply if this remedy does not fully
        compensate you for any losses or fails of its essential purpose.
      </p>
      <h2 id="severability">Severability</h2>
      <p>
        All rights and restrictions contained in this Agreement may be exercised and shall be
        applicable and binding only to the extent that they do not violate any applicable laws and
        are intended to be limited to the extent necessary so that they will not render this
        Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of
        this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent
        jurisdiction, it is the intention of the parties that the remaining provisions or portions
        thereof shall constitute their agreement with respect to the subject matter hereof, and all
        such remaining provisions or portions thereof shall remain in full force and effect.
      </p>
      <h2 id="dispute-resolution">Dispute resolution</h2>
      <p>
        The formation, interpretation, and performance of this Agreement and any disputes arising
        out of it shall be governed by the substantive and procedural laws of Israel without regard
        to its rules on conflicts or choice of law and, to the extent applicable, the laws of
        Israel. The exclusive jurisdiction and venue for actions related to the subject matter
        hereof shall be the courts located in Israel, and you hereby submit to the personal
        jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding
        arising out of or related to this Agreement. The United Nations Convention on Contracts for
        the International Sale of Goods does not apply to this Agreement.
      </p>
      <h2 id="assignment">Assignment</h2>
      <p>
        You may not assign, resell, sub-license or otherwise transfer or delegate any of your rights
        or obligations hereunder, in whole or in part, without our prior written consent, which
        consent shall be at our own sole discretion and without obligation; any such assignment or
        transfer shall be null and void. We are free to assign any of its rights or obligations
        hereunder, in whole or in part, to any third party as part of the sale of all or
        substantially all of its assets or stock or as part of a merger.
      </p>
      <h2 id="changes-and-amendments">Changes and amendments</h2>
      <p>
        We reserve the right to modify this Agreement or its terms related to the Website and
        Services at any time at our discretion. When we do, we will revise the updated date at the
        bottom of this page. We may also provide notice to you in other ways at our discretion, such
        as through the contact information you have provided.
      </p>
      <p>
        An updated version of this Agreement will be effective immediately upon the posting of the
        revised Agreement unless otherwise specified. Your continued use of the Website and Services
        after the effective date of the revised Agreement (or such other act specified at that time)
        will constitute your consent to those changes.
      </p>
      <h2 id="acceptance-of-these-terms">Acceptance of these terms</h2>
      <p>
        You acknowledge that you have read this Agreement and agree to all its terms and conditions.
        By accessing and using the Website and Services you agree to be bound by this Agreement. If
        you do not agree to abide by the terms of this Agreement, you are not authorized to access
        or use the Website and Services.
      </p>
      <h2 id="contacting-us">Contacting us</h2>
      <p>
        If you have any questions, concerns, or complaints regarding this Agreement, we encourage
        you to contact us using the details below:
      </p>
      <p>
        <a
          href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;info&#64;t&#101;&#114;r&#105;f&#105;c&#108;&#105;&#118;e&#46;c&#111;m"
          >&#105;nf&#111;&#64;&#116;e&#114;&#114;ificli&#118;&#101;.&#99;&#111;&#109;</a
        >
      </p>
      <p>This document was last updated on November 8, 2022</p></ng-template
    >`,
  },
];
