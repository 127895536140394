import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ConfirmModalComponent} from '../modals/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private modalService: NgbModal) {}

  public showConfirm(title: string = '', text: string = ''): Observable<boolean> {
    const resultSubject = new Subject<boolean>();

    const modal = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'dark-modal ltr',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });

    (modal.componentInstance as ConfirmModalComponent).title = title;
    (modal.componentInstance as ConfirmModalComponent).text = text;

    modal.result.then(
      (result: boolean) => {
        if (result) {
          resultSubject.next(true);
        } else {
          resultSubject.next(false);
        }

        resultSubject.complete();
      },
      () => {
        resultSubject.next(false);
        resultSubject.complete();
      }
    );
    return resultSubject;
  }
}
