import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
  CSV_Strategies,
  CSV_Strategy,
} from '../../../../../shared/ecommerce-platform-integration/csv-strategies';

@Component({
  selector: 'app-add-products-modal',
  templateUrl: './add-products-modal.component.html',
  styleUrls: ['./add-products-modal.component.scss'],
})
export class AddProductsStrategySelectModalComponent {
  @Input() products: any[] = [];
  @Input() storeId: string = '';
  @Input() storeName: string = '';

  options = Object.entries(CSV_Strategies).map((strategy) => ({
    name: strategy[0],
    value: strategy[1],
  }));
  selectedOption: CSV_Strategy = this.options[1].value;

  constructor(public modal: NgbActiveModal) {}

  addProductsToStore(): void {
    this.modal.close(this.selectedOption);
  }
  closeModal(): void {
    this.modal.close();
  }
}
