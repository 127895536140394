<div class="modal-header">
  <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss()">
    Close
  </button>
  <h4 class="modal-title text-center" id="modal-title">
    {{ showPhone ? 'Create' : 'Add' }} New Admin User
  </h4>
</div>
<div class="modal-body">
  <form name="userForm" #userForm="ngForm">
    <div class="form-row justify-content-center">
      <div class="col-10">
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            class="form-control"
            id="email"
            name="email"
            placeholder="Email"
            autocomplete="off"
            [(ngModel)]="email"
            email
            required
          />
          <label *ngIf="showPhone" for="phoneNumber">Phone</label>
          <input
            *ngIf="showPhone"
            type="tel"
            class="form-control"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="+972"
            autocomplete="off"
            [(ngModel)]="phoneNumber"
            phoneNumber
            required
          />
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-yellow w-132"
    (click)="save(userForm)"
    [disabled]="isSubmitting"
  >
    Save
  </button>
</div>
