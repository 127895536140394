import type {FirestoreTimestamp} from '../types/firestore';

export type SMSData = {
  sendWhen: FirestoreTimestamp;
  wasSent: boolean;
  smsBody: string;
  phoneNumbers: string[];
  provider?: SMSProvider;
  selectedStoresIds: string[];
  selectedSessionsIds: string[];
  defaultCountryCode?: string;
  isTest: boolean;
  successes?: number;
  failed?: number;
  total?: number;
};

export enum SMSProvider {
  Twilio = 'Twilio',
  AWS = 'AWS',
}
