<div class="container-lg">
  <div class="row">
    <div class="col-12">
      <section class="users-page">
        <!-- Page cover -->
        <div class="page-cover page-cover-user">
          <h1 class="welcome-text">Admin Users</h1>
        </div>

        <app-list-view [settings]="listViewSettings">
          <ng-template appItemActionsContent let-user="item" let-i="index">
            <button type="button" class="btn btn-default btn-sm ml-2" (click)="removeUser(user)">
              Remove
            </button>
          </ng-template>
        </app-list-view>
      </section>
    </div>
  </div>
</div>
