import {Component, inject} from '@angular/core';
import {SmsService} from '../../services/sms.service';
import {SMSProvider} from '../../../../../shared/db-models/sms';
import {StoresService} from 'src/app/services/stores.service';
import {map} from 'rxjs';

@Component({
  selector: 'app-sms-creation',
  templateUrl: './sms-creation.component.html',
  styleUrls: ['./sms-creation.component.scss'],
})
export class SmsCreationComponent {
  public smsService = inject(SmsService);
  public storesService = inject(StoresService);
  public phoneNumbers: string[];
  public numberOfPhones: number;
  public providers = Object.keys(SMSProvider);

  public messages$ = this.smsService.getAll().pipe(
    map((data) =>
      data.map((data) => {
        const string = JSON.stringify({...data, sendWhen: data.sendWhen.toDate()});
        return string;
      })
    )
  );

  sendSms(
    phonesList: string,
    message: string,
    date: string,
    provider: string,
    defaultCountryCode: string,
    isTest: boolean,
    sessions: string,
    stores: string
  ) {
    const time = new Date(date);
    this.phoneNumbers = phonesList.split(',');
    this.numberOfPhones = this.phoneNumbers.length;

    if (!(provider in SMSProvider)) {
      alert('Invalid provider');
      return;
    }

    const sessionsIds = sessions.split(',').map((session) => session.trim());
    const storesIds = stores.split(',').map((store) => store.trim());
    console.log(message, isTest, defaultCountryCode, time);

    this.smsService
      .sendSmsInBulk(
        this.phoneNumbers,
        message,
        time,
        provider as SMSProvider,
        storesIds,
        sessionsIds,
        defaultCountryCode,
        isTest
      )
      .subscribe({
        error: (err) => alert(err),
      });
  }
}
