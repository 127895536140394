<div id="form-container">
  <bs5-unix-cron name="cron" [(ngModel)]="value"> </bs5-unix-cron>
  <input class="form-control" readonly [value]="value" />
  <div class="mt-3">
    *Note: The time specified in the cron string is based on UTC+0. Please adjust your schedule
    accordingly to match your desired time.
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-yellow w-132" (click)="confirm()">Confirm</button>
  <button type="button" class="btn btn-yellow w-132" (click)="disableAutomation()">
    Disable Automation
  </button>
  <button type="button" class="btn btn-danger w-132" (click)="close()">Cancel</button>
</div>
