import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {UsersService} from '../../services/users.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnDestroy {
  connectedUser;
  isLoading = true;

  private subsCleanup: Subscription[] = [];

  constructor(private usersService: UsersService) {
    this.connectedUser = this.usersService.getConnectedUserSync();
  }

  ngOnDestroy(): void {
    for (const currSub of this.subsCleanup) {
      currSub.unsubscribe();
    }
  }
}
