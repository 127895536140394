<div class="container-lg">
  <div class="row">
    <div class="col-12">
      <section class="settings-page">
        <!-- Page cover -->
        <div class="page-cover page-cover-user">
          <h1 class="welcome-text">Currencies</h1>
        </div>

        <ng-container *ngIf="!isLoading">
          <app-list-view [settings]="listViewSettings"> </app-list-view>
        </ng-container>
      </section>
    </div>
  </div>
</div>
