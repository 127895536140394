import {priceShoesCSVTToTerrificProductStrategy} from './priceshoes';
import {terrificCSVTToTerrificProductStrategy} from './terrific';
import {CSV_Strategy} from './types';

export const CSV_Strategies: {[name: string]: CSV_Strategy} = {
  PriceShoes: priceShoesCSVTToTerrificProductStrategy,
  Terrific: terrificCSVTToTerrificProductStrategy,
} as const;

export {CSV_Strategy};
