export function sanitizeExternalURL(url: string) {
  let cleanedUrl = url.replace(/^https?:\/\/www\./, (match) => match.replace('www.', ''));

  const domainMatch = cleanedUrl.match(/^https?:\/\/[^\/]+/);
  if (domainMatch) {
    cleanedUrl = domainMatch[0];
  }

  return cleanedUrl;
}
