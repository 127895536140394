<h1 class="modal-title">Add Products</h1>
<p class="modal-subtitle">
  Adding products from the file selected with {{ products.length }} rows <br />
  The name of the store is {{ storeName }} <br />And the store id is {{ storeId }}
</p>

<h2>Please select file rows type</h2>
<ng-select
  [items]="options"
  bindLabel="name"
  bindValue="value"
  [(ngModel)]="selectedOption"
></ng-select>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="addProductsToStore()">Add Products</button>
  <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
</div>
