import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {
  faDollarSign,
  faTh,
  faHome,
  faUsersCog,
  faSms,
  faTrash,
  faFileUpload,
} from '@fortawesome/free-solid-svg-icons';
import {UsersService} from '../../services/users.service';
import {SwiperOptions} from 'swiper';
import {currentYearString} from '../../../../../shared/utilities/times/times';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
})
export class AppLayoutComponent implements OnInit {
  // Icons
  faHome = faHome;
  faDollarSign = faDollarSign;
  faTh = faTh;
  faUsersCog = faUsersCog;
  faSms = faSms;
  faTrash = faTrash;
  faFileUpload = faFileUpload;

  currentYear = currentYearString;

  // ScrollBar for sidebar
  public swiperConfigSidebar: SwiperOptions = {
    direction: 'vertical',
    slidesPerView: 'auto',
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    mousewheel: true,
    freeMode: true,
    touchReleaseOnEdges: true,
  };

  showSidebar = false;

  connectedUser;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.connectedUser = this.usersService.getConnectedUserSync();

    this.usersService.connectedUserChanged.subscribe((connectedUser) => {
      this.connectedUser = connectedUser;
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showSidebar = false;
        this.cdr.detectChanges();
      }
    });
  }

  // Sidebar toggle
  public toggleSidebar(): void {
    this.showSidebar = !this.showSidebar;
  }

  public logout(): void {
    this.usersService.signOut().then(() => {
      this.router.navigate(['/login']);
    });
  }
}
