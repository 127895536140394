import {Component, Input, OnInit} from '@angular/core';
import {User} from '@angular/fire/auth';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {UsersService} from 'src/app/services/users.service';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.scss'],
})
export class DeleteUserModalComponent implements OnInit {
  @Input() userModel: User;

  constructor(
    public usersService: UsersService,
    public toastr: ToastrService,
    public modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    console.log('test');
  }

  public async deleteUser(userModel: DeleteUserModalComponent['userModel']) {
    try {
      this.usersService.deleteUser(userModel).subscribe((response) => {
        if (response == 'ok') {
          this.toastr.success('User Deleted');
        }
        this.modal.close();
      });
    } catch (error) {
      this.toastr.error('Error has occurred:', '' + error);
      this.modal.close();
    }
  }
  public async openCloseDeleteUserModal() {
    this.modal.close();
  }
}
